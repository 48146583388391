import { createSlice, current } from '@reduxjs/toolkit'
import { getTotalAmount } from '@src/views/services/purchaseOrders/store/bomTax.service'
import { BID_VALIDITY } from '@src/utility/Constants/CreateRfQConstants'

/**
 *
 * @param {number} bidValidity default 15 Days
 * @returns {number} 15 Days from today
 */
export const bidClosingDate = (bidValidity) => {
  const now = new Date()
  now.setDate(now.getDate() + bidValidity)
  return now
}

const initialState = {
  id: '',
  title: '',
  rfqId: '',
  status: '',
  companyCode: '',
  deliveryContactDetails: {},
  createdBy: '',
  approverUserId: '',
  indentNumber: '',
  projectId: {},
  suppliers: [],
  // costMetrics: {
  //   gst: Boolean,
  //   frieght: Boolean,
  //   shifting: Boolean
  // },
  materials: [],
  termsAndConditions: [],
  supplierCount: 0,
  bidCriteria: {
    openDate: new Date(),
    closeDate: bidClosingDate(BID_VALIDITY),
    validity: BID_VALIDITY,
    deliveryUptoDate: bidClosingDate(BID_VALIDITY)
  },
  documentsRequired: [],
  budget: {
    maxValue: '',
    visibleToSeller: false
  },
  sellerToAward: {},
  includesGSTFlag: false,
  sellerResponses: [],
  vendorCriteria: {
    evaluationMatrix: '',
    preferredVendors: []
  },
  quoteAmounts: []
}

const updateAmounts = (action) => {
  return action.payload.quotes.map((quote) => {
    const materials = quote.bomResponse.map((material) => {
      const rfqMaterial = action.payload.rfq.materials.find((rfqMaterial) => rfqMaterial.id === material.bomId)
      const gstRate = material.gstRate || 0
      const finalUnitRate = material.unitRate * (1 + (gstRate || 0) / 100)
      const materialTotalAmount = finalUnitRate * rfqMaterial.quantity

      return {
        bomId: material.bomId,
        gstRate: gstRate,
        unitRate: finalUnitRate.toFixed(2),
        quantity: rfqMaterial.quantity,
        totalAmount: materialTotalAmount.toFixed(2)
      }
    })

    // Cost metrics calculations
    const costMetrics = quote.costMetrics || {}
    const transportPrice = costMetrics?.transport?.price || 0
    const transportGstRate = costMetrics?.transport?.gstPercentage || 0
    const handlingPrice = costMetrics?.handling?.price || 0
    const handlingGstRate = costMetrics?.handling?.gstPercentage || 0
    const otherChargesPrice = costMetrics?.otherCharges?.price || 0
    const otherChargesGstRate = costMetrics?.otherCharges?.gstPercentage || 0

    const calculateCost = (price, gstRate) => {
      return parseFloat(price * (1 + gstRate / 100)).toFixed(2)
    }

    const transportAmount = calculateCost(transportPrice, transportGstRate)
    const handlingAmount = calculateCost(handlingPrice, handlingGstRate)
    const otherChargesAmount = calculateCost(otherChargesPrice, otherChargesGstRate)

    const totalMaterialCost = materials.reduce((total, material) => {
      const rfqMaterial = action.payload.rfq.materials.find((rfqMaterial) => rfqMaterial.id === material.bomId)
      const price = material.unitRate * rfqMaterial.quantity
      return total + parseFloat(price)
    }, 0)

    const totalAmountWithGst =
      parseFloat(totalMaterialCost) +
      parseFloat(transportAmount) +
      parseFloat(handlingAmount) +
      parseFloat(otherChargesAmount)

    return {
      rfqResponseId: quote.rfqResponseId,
      transport: transportAmount,
      handling: handlingAmount,
      otherCharges: otherChargesAmount,
      materials,
      total: parseFloat(totalAmountWithGst).toFixed(2),
      totalAmount: parseFloat(totalMaterialCost).toFixed(2)
    }
  })
}

export const rfqManager = createSlice({
  name: 'rfq-manager',
  initialState,
  reducers: {
    setTitle: (state, action) => {
      state.title = action.payload
    },
    setDeliveryContactDetails: (state, action) => {
      state.deliveryContactDetails = { ...state.deliveryContactDetails, [action.payload.name]: action.payload.value }
    },
    setApprover: (state, action) => {
      state.approverUserId = action.payload
    },
    setRFQCreator: (state, action) => {
      state.createdBy = action.payload
    },
    // setCostMetrics: (state, action) => {
    //   state.costMetrics[Object.keys(action.payload)] = action.payload[Object.keys(action.payload)]
    // },
    setSuppliers: (state, action) => {
      state.suppliers = action.payload
    },
    setSuppliersCount: (state, action) => {
      state.supplierCount = action.payload
    },
    setTermsAndConditions: (state, action) => {
      const { tncId, Description, heading, srNo } = action.payload
      const record = state.termsAndConditions.find((tc) => tc.srNo === +srNo)

      if (record) {
        const updatedRecord = { ...record, Description, heading }
        const filterArray = state.termsAndConditions.filter((f) => f.srNo !== +record.srNo)
        state.termsAndConditions = [...filterArray, updatedRecord]
      } else {
        state.termsAndConditions.push({ tncId, Description, heading, srNo: +srNo })
      }
    },
    removeTermAndCondition: (state, action) => {
      const updatedState = state.termsAndConditions
        .filter((item) => {
          return item.srNo !== +action.payload
        })
        .map((item) => ({ ...item }))
      state.termsAndConditions = updatedState
    },
    setProject: (state, action) => {
      state.projectId = action.payload
    },
    setMaterials: (state, action) => {
      const totalAmounts = getTotalAmount(action.payload)
      state.materials = state.materials.concat({
        ...action.payload,
        ...totalAmounts
      })
    },
    removeMaterials(state, action) {
      state.materials = state.materials.filter((item) => item.id !== action.payload)
    },
    editMaterials(state, action) {
      const totalAmounts = getTotalAmount(action.payload)
      const updatedItem = { ...action.payload, ...totalAmounts }
      state.materials = state.materials.map((item) => (item.id == action.payload.id ? updatedItem : item))
    },
    resetDeliveryContactDetails: (state) => {
      state.deliveryContactDetails = initialState.deliveryContactDetails
    },
    editRFQConfigurationState: (state, action) => {
      const rfqCopy = JSON.parse(JSON.stringify(action.payload))
      return {
        ...state,
        ...rfqCopy
      }
    },
    setBidOpenDate: (state, action) => {
      state.bidCriteria.openDate = action.payload[0]
    },
    setBidCloseDate: (state, action) => {
      state.bidCriteria.closeDate = action.payload[0]
    },
    setDeliveryUptoDate: (state, action) => {
      state.bidCriteria.deliveryUptoDate = action.payload[0]
    },
    setBidValidity: (state, action) => {
      state.bidCriteria.validity = action.payload
    },
    setBudget: (state, action) => {
      state.budget.maxValue = action.payload
    },
    setIsBudgetVisible: (state, action) => {
      state.budget.visibleToSeller = action.payload
    },
    setSellerToAward: (state, action) => {
      state.sellerToAward = action.payload
    },
    resetMaterialDocPrefix: (state, action) => {
      const filteredMaterials = current(state.materials).map((m) => {
        if (action.payload === m.uploadedDocumentKey) {
          return { ...m, uploadedDocumentKey: '' }
        }
        return m
      })
      state.materials = filteredMaterials
    },
    setRequiredDocuments: (state, action) => {
      state.documentsRequired = action.payload
    },
    setIncludesGSTFlag: (state, action) => {
      state.includesGSTFlag = action.payload
    },
    resetProject: (state, action) => {
      state.projectId = initialState.projectId
    },
    setCartMaterials: (state, action) => {
      state.materials =
        action.payload || localStorage.getItem('cartItems') ? JSON.parse(localStorage.getItem('cartItems')) : []
    },
    setSellerResponses: (state, action) => {
      state.sellerResponses = action.payload
    },
    setEvaluationMatrix: (state, action) => {
      state.vendorCriteria.evaluationMatrix = action.payload
    },
    setPreferredVendors: (state, action) => {
      state.vendorCriteria.preferredVendors = action.payload
    },
    setQuoteAmounts: (state, action) => {
      const quotes = updateAmounts(action)
      state.quoteAmounts = quotes
    },
    excludeMatrixFromQuote: (state, action) => {
      const { isGst, quotes, rfq } = action.payload

      if (!isGst) {
        const updatedQuotes = quotes.map((quote) => {
          const materials = quote.bomResponse.map((material) => {
            const rfqMaterial = rfq.materials.find((rfqMaterial) => rfqMaterial.id === material.bomId)
            const gstRate = material.gstRate || 0
            const finalUnitRate = material.unitRate
            const materialTotalAmount = finalUnitRate * rfqMaterial.quantity

            return {
              bomId: material.bomId,
              gstRate: gstRate,
              unitRate: finalUnitRate.toFixed(2),
              quantity: rfqMaterial.quantity,
              totalAmount: materialTotalAmount.toFixed(2)
            }
          })

          // Cost metrics calculations
          const costMetrics = quote.costMetrics || {}
          const transportPrice = costMetrics?.transport?.price || 0
          const handlingPrice = costMetrics?.handling?.price || 0
          const otherChargesPrice = costMetrics?.otherCharges?.price || 0

          const totalMaterialCost = materials.reduce((total, material) => {
            const rfqMaterial = rfq.materials.find((rfqMaterial) => rfqMaterial.id === material.bomId)
            const price = material.unitRate * rfqMaterial.quantity
            return total + parseFloat(price)
          }, 0)

          const totalAmountWithGst =
            parseFloat(totalMaterialCost) +
            parseFloat(handlingPrice) +
            parseFloat(transportPrice) +
            parseFloat(otherChargesPrice)

          return {
            rfqResponseId: quote.rfqResponseId,
            transport: transportPrice,
            handling: handlingPrice,
            otherCharges: otherChargesPrice,
            materials,
            total: parseFloat(totalAmountWithGst).toFixed(2),
            totalAmount: parseFloat(totalMaterialCost).toFixed(2)
          }
        })

        state.quoteAmounts = updatedQuotes
      } else {
        const updatedQuotes = updateAmounts(action)
        state.quoteAmounts = updatedQuotes
      }
    },
    removeMaterialForNewRequirement(state, action) {
      const materialIndex = state.materials.findIndex((item) => item.id === action.payload)
      if (materialIndex !== -1) {
        const updatedMaterial = {
          ...state.materials[materialIndex],
          isDeleted: true
        }
        const updatedMaterials = [
          ...state.materials.slice(0, materialIndex),
          updatedMaterial,
          ...state.materials.slice(materialIndex + 1)
        ]
        state.materials = updatedMaterials
      }
    },
    addBackMaterialForNewRequirement(state, action) {
      const materialIndex = state.materials.findIndex((item) => item.id === action.payload)
      if (materialIndex !== -1) {
        const updatedMaterial = {
          ...state.materials[materialIndex],
          isDeleted: false
        }
        const updatedMaterials = [
          ...state.materials.slice(0, materialIndex),
          updatedMaterial,
          ...state.materials.slice(materialIndex + 1)
        ]
        state.materials = updatedMaterials
      }
    },
    resetForm: (state, action) => {
      return initialState
    }
  }
})

export const {
  setTitle,
  setDeliveryContactDetails,
  setApprover,
  setRFQCreator,
  setSuppliers,
  setSuppliersCount,
  setTermsAndConditions,
  removeTermAndCondition,
  setProject,
  setMaterials,
  removeMaterials,
  editMaterials,
  editRFQConfigurationState,
  resetForm,
  setBidOpenDate,
  setBidCloseDate,
  setDeliveryUptoDate,
  setBidValidity,
  setBudget,
  setIsBudgetVisible,
  setSellerToAward,
  resetMaterialDocPrefix,
  setIncludesGSTFlag,
  setRequiredDocuments,
  resetProject,
  setCartMaterials,
  setSellerResponses,
  setEvaluationMatrix,
  setPreferredVendors,
  setQuoteAmounts,
  excludeMatrixFromQuote,
  removeMaterialForNewRequirement,
  addBackMaterialForNewRequirement
} = rfqManager.actions
export default rfqManager.reducer
