import { createSlice } from '@reduxjs/toolkit'
import { getTotalAmount } from './boxTax.service'

const calculateAmount = (unitRate, quantity, gstRate) => {
  const amount = unitRate * quantity
  const gstAmount = (amount * gstRate) / 100
  const totalAmount = amount + gstAmount
  return { gstAmount, totalAmount }
}

const EMPTY_GRN_ORDER = {
  billOfMaterials: [],
  otherCharges: {
    transport: {
      price: 0,
      gstPercentage: 0
    },
    handling: {
      price: 0,
      gstPercentage: 0
    },
    otherCharges: {
      price: 0,
      gstPercentage: 0
    }
  },
  isGstIncludedInPrice: false
}

export const GRN_MODAL_MODE = {
  EDIT: 'EDIT',
  VIEW: 'VIEW',
  CREATE: 'CREATE'
}

const grnSlice = createSlice({
  name: 'grns',
  initialState: {
    poModalMode: GRN_MODAL_MODE.CREATE,
    activeGRN: EMPTY_GRN_ORDER
  },
  reducers: {
    resetStateGrnDirect: (state) => {
      state.poModalMode = GRN_MODAL_MODE.CREATE
      state.activeGRN = { ...EMPTY_GRN_ORDER }
    },
    setTransportChargesInDirectGrn: (state, action) => {
      state.activeGRN.otherCharges.transport.price = action.payload.price
      state.activeGRN.otherCharges.transport.gstPercentage = action.payload.gstPercentage
    },
    setHandlingChargesInDirectGrn: (state, action) => {
      state.activeGRN.otherCharges.handling.price = action.payload.price
      state.activeGRN.otherCharges.handling.gstPercentage = action.payload.gstPercentage
    },
    setAdditionalChargesInDirectGrn: (state, action) => {
      state.activeGRN.otherCharges.otherCharges.price = action.payload.price
      state.activeGRN.otherCharges.otherCharges.gstPercentage = action.payload.gstPercentage
    },
    setIsGstIncludedInPriceInDirectGrn: (state, action) => {
      state.activeGRN.isGstIncludedInPrice = action.payload
    },
    addBomItem: (state, action) => {
      const { quantity, gstRate, unitRate, itemId } = action.payload

      const updatedMaterials = () => {
        const { gstAmount, totalAmount } = calculateAmount(quantity, gstRate, unitRate)
        return {
          gstRate: gstRate,
          gstAmount,
          quantity: quantity,
          unitRate: unitRate,
          totalAmount,
          itemId
        }
      }

      const data = updatedMaterials()

      state.activeGRN.billOfMaterials.push(data)
    }
  }
})

export const {
  addBomItem,
  setTransportChargesInDirectGrn,
  setHandlingChargesInDirectGrn,
  setAdditionalChargesInDirectGrn,
  setIsGstIncludedInPriceInDirectGrn,
  resetStateGrnDirect
} = grnSlice.actions

export default grnSlice.reducer
