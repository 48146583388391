export const RoleAbilityMapping = {
  BUYER: [
    {
      action: 'allow',
      subject: 'acl-common'
    },
    {
      action: 'allow',
      subject: 'buyer-services'
    },
    {
      action: 'allow',
      subject: 'buyer-rfq-service'
    },
    {
      action: 'allow',
      subject: 'chat-services'
    },
    {
      action: 'allow',
      subject: 'auction-service'
    },
    {
      action: 'allow',
      subject: 'my-profile'
    },
    {
      action: 'allow',
      subject: 'grn-service'
    },
    {
      action: 'allow',
      subject: 'templates'
    }
  ],
  STORE_MANAGER: [
    {
      action: 'allow',
      subject: 'acl-common'
    },
    {
      action: 'allow',
      subject: 'store-manager'
    },
    {
      action: 'allow',
      subject: 'grn-service'
    },
    {
      action: 'allow',
      subject: 'my-profile'
    }
  ],
  ADMIN: [
    {
      action: 'allow',
      subject: 'acl-common'
    },
    {
      action: 'allow',
      subject: 'chat-services'
    },
    {
      action: 'allow',
      subject: 'buyer-services'
    },
    {
      action: 'allow',
      subject: 'buyer-rfq-service'
    },
    {
      action: 'allow',
      subject: 'acl-billing'
    },
    {
      action: 'allow',
      subject: 'customer-admin'
    },
    {
      action: 'allow',
      subject: 'auction-service'
    },
    {
      action: 'allow',
      subject: 'my-profile'
    },
    {
      action: 'allow',
      subject: 'grn-service'
    },
    {
      action: 'allow',
      subject: 'templates'
    }
  ],
  APPROVER: [
    {
      action: 'allow',
      subject: 'acl-common'
    },
    {
      action: 'allow',
      subject: 'buyer-services'
    },
    {
      action: 'allow',
      subject: 'buyer-rfq-service'
    },
    {
      action: 'allow',
      subject: 'auction-service'
    },
    {
      action: 'allow',
      subject: 'my-profile'
    },
    {
      action: 'allow',
      subject: 'templates'
    }
  ],
  SELLER: [
    {
      action: 'allow',
      subject: 'acl-common'
    },
    {
      action: 'allow',
      subject: 'customer-supplier'
    },
    {
      action: 'allow',
      subject: 'my-profile'
    },
    {
      action: 'allow',
      subject: 'seller-services'
    },
    {
      action: 'allow',
      subject: 'seller-bid-services'
    },
    {
      action: 'allow',
      subject: 'seller-admin'
    }
  ],
  MARKETING_PARTNER: [
    {
      action: 'allow',
      subject: 'acl-common'
    },
    {
      action: 'allow',
      subject: 'chat-services'
    },
    {
      action: 'allow',
      subject: 'mp-services'
    },
    {
      action: 'allow',
      subject: 'my-profile'
    },
    {
      action: 'allow',
      subject: 'mp-bid-services'
    },
    {
      action: 'allow',
      subject: 'buyer-rfq-service'
    },
    {
      action: 'allow',
      subject: 'seller-bid-services'
    }
  ],
  SUPER_ADMIN: [
    {
      action: 'manage',
      subject: 'all'
    }
  ]
}
